.cv {
  max-width: 750px;
  margin: 0 auto;
  padding: $grid-gutter $grid-gutter / 2 $grid-gutter * 2;

  & > h2:first-of-type {
    margin-top: $grid-gutter / 2 !important;
  }

  h2 {
    margin-top: $grid-gutter * 1.5;
    margin-bottom: $grid-gutter / 1.5;
  }

  hr {
    margin-top: $grid-gutter;
  }
}
