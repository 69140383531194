*::selection {
  color: white;
  background-color: black;
}

img {
  display: block;
  width: 100%;
}

body {
  position: relative;
}