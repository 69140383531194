/*==============================================================================
HEADER
==============================================================================*/
header {
  padding: $grid-gutter / 2;
  position: relative;
  left: 0;
  top: 0;
  z-index: 667;
  width: calc(100% - 30px);

  h4 {
    font-size: 1.3em;
  }
}

nav {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.logo {
  color: #cccccc;
}

/*==============================================================================
MAIN
==============================================================================*/

main {
}
/*==============================================================================
FOOTER
==============================================================================*/
footer {
}
